<template>
  <div>
    <div class="ntDrag__head" style="background-image:linear-gradient(150deg, #ff8952, #ff5722);color:#ffdccb;position:relative;z-index:11;"><i class="iconfont icon-hongbao fs-14"></i> 发红包</div>
     <div class="ntMain__cont flex1 flexbox flex-col">
        <div class="nt__redpacket"><div class="redpacket__item">
           <div class="redpacket__item-inner">
              <div class="flexbox flex-alignc">金额<input class="redpacket__item-input" type="text" v-model="mount" placeholder="0.00" maxlength="4" />元
             </div>
            </div><p class="redpacket__item-subtxt">随机红包金额</p>
          </div>
         <div class="redpacket__item">
         <div class="redpacket__item-inner"><div class="flexbox flex-alignc">红包个数<input class="redpacket__item-input" type="text" placeholder="填写个数" />个
            </div>
          </div>
          <p class="redpacket__item-subtxt">本群共201人</p></div>
         <div class="redpacket__item">
             <div class="redpacket__item-inner">
          <div class="flexbox flex-alignc"><textarea class="redpacket__item-area" name="content" placeholder="恭喜发财，大吉大利"></textarea>
          </div></div></div>
            <div class="redpacket__item">
             <div class="align-c pad10" style="color:#ff5722;"><em class="fs-42">￥</em> <span class="redpacketNum fs-50">{{mount ? parseFloat(mount).toFixed(2) : '0.00'}}</span></div>
          </div><div class="redpacket__item">
            <div class="vui__btn vui__btn-primary" style="background:#ff5722;color:#ffdccb;box-shadow:0 1px 3px #cc781f;margin:0 auto;height:34px;width:150px;" @click="handleSubmit"><i class="iconfont icon-hongbao"></i> 塞钱进红包</div>
           </div>
         </div>
     </div>
 </div>
</template>
<script>
import { ref, inject } from 'vue'
export default {
  components: {},
    setup() {
     const mount = ref('')
    const v3layer = inject('v3layer')
      const handleSubmit = () => {
        let num = mount.value ? mount.value : '0.00'
       v3layer.message({icon: 'success', content: '输入的金额为：￥' + num, xclose: true})
     }
     return { mount, handleSubmit
     }
 }
}
</script>
<style>
.nt__redpacket {margin-top: 10px;}.redpacket__item {margin-top: 10px; padding: 0 35px;}.redpacket__item-inner {background: #fff; border-radius: 2px; box-shadow: 0 1px 2px #ddd; color: #353535; padding: 0 10px;}.redpacket__item-subtxt {color: #999; font-size: 12px; padding: 2px 0 0 10px;}
.redpacket__item-input {flex: 1; background: none; border: 0; color: #23252f; font-size: 14px; padding: 12px; text-align: right;}.redpacket__item-area {flex: 1; background: none; border: 0; color: #23252f; font-size: 14px; font-family: arial; resize: none; padding-top: 10px; height: 60px;}
</style>

